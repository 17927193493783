import React, { Fragment, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Games from './Games'
import GameDetails from './GameDetails'
import firebase  from 'firebase/app'
import databases from './databases'

const App = () => {
  let firebaseApps = []

  for (let i = 0; i < databases.length; i++) {
    if (!databases[i].firebaseConfig) {
      continue
    }
    let newApp = {
      id: databases[i].id,
      siteTitle: databases[i].siteTitle,
      image: databases[i].image,
      firebaseApp: firebase.initializeApp(databases[i].firebaseConfig, databases[i].id)
    }
    firebaseApps.push(newApp)

  }

  return (
    <Router>
      <Switch>

        {/* Default database will be at root route */}
        <Route exact path='/' render={(props) => (
          <Games {...props} app={firebaseApps[0]} />
        )} />

        {firebaseApps.map((item, index) => (
          <Route key={index} exact path={`/${item.id}`} render={(props) => (
            <Games {...props} app={item} />
          )} />
        ))}
        {firebaseApps.map((item, index) => (
          <Route key={index} exact path={`/${item.id}/game/:id`} render={(props) => (
            <GameDetails {...props} app={item} />
          )} />
        ))}

      </Switch>
    </Router>
  )
}

export default App
