import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Form, Col, Row } from 'react-bootstrap'
import { AiOutlineSearch } from 'react-icons/ai'
import { FaAngleRight, FaPlus } from 'react-icons/fa'
import moment from 'moment'
import "firebase/database";
import { Helmet } from 'react-helmet';


const Games = (props) => {
  const { app } = props
  const avatar = app?.image
  const [games, setGames] = useState([])
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const openGame = (id) => {
    history.push(`${app?.id}/game/${id}`)
  }

  useEffect(() => {
    if (app?.firebaseApp) {
      let myref = app.firebaseApp.database().ref('Games')
      setIsLoading(true)
      myref.on('value', (snap) => {
        let games = snap.val()
        let gamesData = []
        for (const [key, value] of Object.entries(games)) {
          if (value.a_score) {
            gamesData.push({
              id: key,
              ...value
            })
          }
        }
        setGames(gamesData)
        setIsLoading(false)
      })
    }
  }, [app?.firebaseApp])

  return (
    <Container className='game-container'>
      <div className='header'>
      <Helmet>
      <title> {app?.siteTitle} Scorecast </title>
      <meta name="title" content="View hhhhmes"/>
      <meta name="description" content="mmmm"/>
      </Helmet>
        <h2 className='heading'>{app?.siteTitle}</h2>
      </div>
      <div className='search-container'>
        <Form.Control type="text" placeholder="Search" className='search-input' onChange={(e) => setSearch(e.target.value.toLowerCase())} />
        <AiOutlineSearch className='icon-search' />
      </div>
      <div className='games-container'>
        <Row>
          {isLoading ? <Col>Loading games...</Col> : ''}
          {games ? games.filter(i => i.a_name.toLowerCase().includes(search) || i.h_name.toLowerCase().includes(search)).sort(function (a, b) { return b.first_updated - a.first_updated }).map((item, index) => (
            <Col key={index} xl={4} lg={4} md={6} sm={12}>
              <div className='game-wrapper' onClick={() => openGame(item.id)}>
                <div className='avatar' style={{ backgroundImage: `url(${avatar}` }}></div>
                <div className='game-info'>
                  <div className='game-info__header'>
                    {moment.duration(moment(new Date()).diff(item?.last_updated)) > 450000
                      ? <div>FINAL</div>
                      : <div className='text-inprogress'>GAME IN PROGRESS</div>}
                  </div>
                  <div className='game-info__team'>
                    <div className='team__name'>
                      {item.a_name}
                    </div>
                    <div className='team__score'>
                      {item?.a_score}
                    </div>
                  </div>
                  <div className='game-info__team'>
                    <div className='team__name'>
                      {item?.h_name}
                    </div>
                    <div className='team__score'>
                      {item?.h_score}
                    </div>
                  </div>
                  <div className='game-time'>
                    {moment(item?.first_updated).format('LLL')}
                  </div>
                </div>
                <FaAngleRight size={30} className='right-arrow' />
              </div>
            </Col>
          )) : ''}
        </Row>
      </div>
    </Container >
  )
}

export default Games
