const databases = [
  {
    id: 'scdemo',
    siteTitle: 'All Games',
	    firebaseConfig: {
	   apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
	  authDomain: "scdemo-c11e0.firebaseapp.com",
	  databaseURL: "https://arenax.firebaseio.com/",
	  projectId: "scdemo-c11e0",
	  storageBucket: "scdemo-c11e0.appspot.com",
	  messagingSenderId: "505094399771",
	  appId: "1:505094399771:web:ed5312825c05a2a9da55be",
	  measurementId: "G-XK2DHDQF9G"
    },
    image: '/images/scdemo.jpg'
  },
  
    {
    id: 'neozone',
    siteTitle: 'Neo Zone Scores',
    firebaseConfig: {
   apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
  authDomain: "scdemo-c11e0.firebaseapp.com",
  databaseURL: "https://neo-zone.firebaseio.com/",
  projectId: "scdemo-c11e0",
  storageBucket: "scdemo-c11e0.appspot.com",
  messagingSenderId: "505094399771",
  appId: "1:505094399771:web:a6af8bc2e84ccecdda55be",
  measurementId: "G-4204XGRQPF"
    },
    image: '/images/neo.png'
  },


   {
    id: 'test1',
    firebaseConfig: {
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
      authDomain: "scdemo-c11e0.firebaseapp.com",
      databaseURL: "https://scdemo-c11e0.firebaseio.com/",
      projectId: "scdemo-c11e0",
      storageBucket: "scdemo-c11e0.appspot.com",
      messagingSenderId: "505094399771",
      appId: "1:505094399771:web:18bcc86f99c6fe79da55be",
      measurementId: "G-7LZQX7S1BQ"
    },
    image: '/images/scdemo.jpg'
  },

   {
    id: 'MAhoops',
    siteTitle: "Marin Academy Basketball",
    firebaseConfig: {
	  apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
	  authDomain: "scdemo-c11e0.firebaseapp.com",
	  databaseURL: "https://marin-academy.firebaseio.com/",
	  projectId: "scdemo-c11e0",
	  storageBucket: "scdemo-c11e0.appspot.com",
	  messagingSenderId: "505094399771",
	  appId: "1:505094399771:web:b32eb6b8747701b2da55be",
	  measurementId: "G-L94V477D9J"
	    },
    image: '/images/MA.png'
  },
  {
    id: 'buzz',
    siteTitle: "SoCal Buzz Basketball",
    firebaseConfig: {
        apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
        authDomain: "scdemo-c11e0.firebaseapp.com",
        databaseURL: "https://socalbuzz.firebaseio.com/",
        projectId: "scdemo-c11e0",
        storageBucket: "scdemo-c11e0.appspot.com",
        messagingSenderId: "505094399771",
        appId: "1:505094399771:web:462517373b5e1f3dda55be",
  measurementId: "G-YV2B1008QR"
    },
    image: '/images/buzz.png'
  },
  {
    id: 'eastHS',
    firebaseConfig: {
    apiKey: "AIzaSyBLmUmkdbleLVZB-gP-9dyNFoQZoUYqGLw",
    authDomain: "cheneystats.firebaseapp.com",
    databaseURL: "https://easths.firebaseio.com/",
    projectId: "cheneystats",
    storageBucket: "cheneystats.appspot.com",
    messagingSenderId: "992518870407",
    appId: "1:992518870407:web:d3f7431871cd9e5388af22"
    },
    image: '/images/easths.png'
  },

     {
    id: 'vipers',
    firebaseConfig: {
     apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
	  authDomain: "scdemo-c11e0.firebaseapp.com",
	  databaseURL: "https://vipers1.firebaseio.com/",
	  projectId: "scdemo-c11e0",
	  storageBucket: "scdemo-c11e0.appspot.com",
	  messagingSenderId: "505094399771",
	  appId: "1:505094399771:web:bd46dede53d3affbda55be",
	  measurementId: "G-D3XNY724PG"
    },
    image: '/images/vipers.png'
  },
    {
    id: 'university',
    firebaseConfig: {
      apiKey: "AIzaSyCP8g8X27lcfVajuVX9ww76br3vYf53cIY",
      authDomain: "headroyce-32d0b.firebaseapp.com",
      databaseURL: "https://uhs2022.firebaseio.com/",
      projectId: "headroyce-32d0b",
      storageBucket: "headroyce-32d0b.appspot.com",
      messagingSenderId: "752422606036",
      appId: "1:752422606036:web:af6f2caca1884ebffb1741",
      measurementId: "G-4186M0GMTV"
    },
    image: '/images/university.png'
  },
      {
    id: 'stleo',
    siteTitle: 'St. Leo Basketball',
    firebaseConfig: {
       apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
  authDomain: "scdemo-c11e0.firebaseapp.com",
  databaseURL: "https://stleo.firebaseio.com/",
  projectId: "scdemo-c11e0",
  storageBucket: "scdemo-c11e0.appspot.com",
  messagingSenderId: "505094399771",
  appId: "1:505094399771:web:9c36536683ca9f26da55be",
  measurementId: "G-7F61WERVC7"
    },
    image: '/images/stleo.png'
  },
  
   {
    id: 'Stleo',
    siteTitle: 'St. Leo Basketball',
    firebaseConfig: {
    apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://stleo.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:9c36536683ca9f26da55be",
    measurementId: "G-7F61WERVC7"
    },
    image: '/images/stleo.png'
  },
 {
    id: 'loc',
    siteTitle: "League of Champions",
    firebaseConfig: {
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
	  authDomain: "scdemo-c11e0.firebaseapp.com",
	  databaseURL: "https://leagueofchamps.firebaseio.com/",
	  projectId: "scdemo-c11e0",
	  storageBucket: "scdemo-c11e0.appspot.com",
	  messagingSenderId: "505094399771",
	  appId: "1:505094399771:web:e2e64393ec8f8b69da55be",
	  measurementId: "G-C30WDD1LYX"
    },
    image: '/images/loc.png'
  },
  {
    id: 'leagueofchampions',
    siteTitle: "League of Champions",
    firebaseConfig: {
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
	  authDomain: "scdemo-c11e0.firebaseapp.com",
	  databaseURL: "https://leagueofchamps.firebaseio.com/",
	  projectId: "scdemo-c11e0",
	  storageBucket: "scdemo-c11e0.appspot.com",
	  messagingSenderId: "505094399771",
	  appId: "1:505094399771:web:16cb49de2199f492da55be",
	  measurementId: "G-EBV241P2N0"
    },
    image: '/images/loc.png'
  },
    {
    id: 'cbhs',
    siteTitle: "Christian Brothers Basketball",
    firebaseConfig: {
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
	  authDomain: "scdemo-c11e0.firebaseapp.com",
	  databaseURL: "https://christianbrothers.firebaseio.com/",
	  projectId: "scdemo-c11e0",
	  storageBucket: "scdemo-c11e0.appspot.com",
	  messagingSenderId: "505094399771",
	  appId: "1:505094399771:web:16cb49de2199f492da55be",
	  measurementId: "G-EBV241P2N0"
    },
    image: '/images/cbhs.jpg'
  },
     {
    id: 'Stleos',
    siteTitle: 'St. Leo Basketball',
    firebaseConfig: {
    apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://stleo.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:9c36536683ca9f26da55be",
    measurementId: "G-7F61WERVC7"
    },
    image: '/images/stleo.png'
  },
     {
    id: 'stleos',
    siteTitle: 'St. Leo Basketball',
    firebaseConfig: {
    apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://stleo.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:9c36536683ca9f26da55be",
    measurementId: "G-7F61WERVC7"
    },
    image: '/images/stleo.png'
  },
     {
    id: 'swoosh',
    firebaseConfig: {
    apiKey: "AIzaSyAD_JYg_ygpIaZfciC19NxfPRboKwv9PCY",
    authDomain: "hoosierstats.firebaseapp.com",
    databaseURL: "https://swooshbball.firebaseio.com/",
    projectId: "hoosierstats",
    storageBucket: "hoosierstats.appspot.com",
    messagingSenderId: "588882983549",
    appId: "1:588882983549:web:ca6c9d58369a76e2100f14",
    measurementId: "G-7VW8N0VE3C"
    },
    image: '/images/swoosh.png'
  },
       {
    id: 'simply',
    siteTitle: 'Simply Basketball',
    firebaseConfig: { 
        apiKey: "AIzaSyA0ts1a7nTz6VFkeuEmgEp0aMKKFCAnyhA",
  authDomain: "sierracanyon-a7d77.firebaseapp.com",
  databaseURL: "https://simply-16969rd.firebaseio.com/",
  projectId: "sierracanyon-a7d77",
  storageBucket: "sierracanyon-a7d77.appspot.com",
  messagingSenderId: "206316659435",
  appId: "1:206316659435:web:a18c9d3d8ee5b35b4314ae",
  measurementId: "G-YHDEEEBWWQ"
    },
    image: '/images/simply.png'
  },
         {
    id: 'Simply',
    siteTitle: 'Simply Basketball',
    firebaseConfig: { 
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
      authDomain: "scdemo-c11e0.firebaseapp.com",
      databaseURL: "https://oaklandathleticleague.firebaseio.com/",
      projectId: "scdemo-c11e0",
      storageBucket: "scdemo-c11e0.appspot.com",
      messagingSenderId: "505094399771",
      appId: "1:505094399771:web:de1cf16e965cbffbda55be",
      measurementId: "G-V8MYSTHEP2"
    },
    image: '/images/oal.png'
  },
        {
    id: 'rebels',
    siteTitle: 'Rebels Basketball',
    siteHeader: 'Rebels Scorecast',
    firebaseConfig: { 
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://rebels.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:a3d65956f56b829bda55be",
    measurementId: "G-M9C7Q38NKH"
    },
    image: '/images/rebels.png'
  },
   {
    id: 'Rebels',
    siteTitle: 'Rebels Basketball',
    siteHeader: 'Rebels Scorecast',
    firebaseConfig: { 
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://rebels.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:a3d65956f56b829bda55be",
    measurementId: "G-M9C7Q38NKH"
    },
    image: '/images/rebels.png'
  },
     {
    id: 'Tribe',
    siteTitle: 'Tribe Athletics',
    firebaseConfig: { 
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://scdemo-tribe.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:a3d65956f56b829bda55be",
    measurementId: "G-M9C7Q38NKH"
    },
    image: '/images/tribe.png'
  },
      {
    id: 'tribe',
    siteTitle: 'Tribe Athletics',
    firebaseConfig: { 
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://scdemo-tribe.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:a3d65956f56b829bda55be",
    measurementId: "G-M9C7Q38NKH"
    },
    image: '/images/tribe.png'
  },
  {
    id: 'oaklandHS',
    siteTitle: 'Oakland High Basketball',
    firebaseConfig: {
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://oaklandhigh.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:1e7386c6c031688bda55be",
    measurementId: "G-51BK3C2X84c86f99c6fe79da55be",
        measurementId: "G-7LZQX7S1BQ"
    },
    image: '/images/oakland.png'
  },
    {
    id: 'Rebels2',
    siteTitle: 'Rebel Stats',
    firebaseConfig: {
      apiKey: "AIzaSyCeKjEsEEvt9EqZ8W9akf925vSnBqLdU_Y",
      authDomain: "sportscloud-80c89.firebaseapp.com",
      databaseURL: "https://sportscloud-rebels2021.firebaseio.com/",
      projectId: "sportscloud-80c89",
      storageBucket: "sportscloud-80c89.appspot.com",
      messagingSenderId: "837751171243",
      appId: "1:837751171243:web:d03b57d4dcb606221defee",
      measurementId: "G-C1YLE5JWE8"
    },
    image: '/images/rebels.png'
  },
   {
    id: 'mack',
    siteTitle: 'Mack Basketball',
    firebaseConfig: {
    apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://mackhouse.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:51393e0c6439a186da55be",
    measurementId: "G-GYHSCHNCZ1"
    },
    image: '/images/mack.png'
  },
    {
    id: 'dstrkt',
    siteTitle: 'DSTRKT Basketball',
    firebaseConfig: {
    apiKey: "AIzaSyDPXWY9EwxJA7H2q36Hp6KWM22aNCWLxHQ",
    authDomain: "dstrkt-6be57.firebaseapp.com",
    databaseURL: "https://dstrkt-6be57-default-rtdb.firebaseio.com",
    projectId: "dstrkt-6be57",
    storageBucket: "dstrkt-6be57.appspot.com",
    messagingSenderId: "415077782045",
    appId: "1:415077782045:web:cf93ed4eeebb16de58ecf4",
    measurementId: "${config.measurementId}"
    },
    image: '/images/DSTRKT.png'
  },
      {
    id: 'DSTRKT',
    siteTitle: 'DSTRKT Basketball',
    firebaseConfig: {
    apiKey: "AIzaSyDPXWY9EwxJA7H2q36Hp6KWM22aNCWLxHQ",
    authDomain: "dstrkt-6be57.firebaseapp.com",
    databaseURL: "https://dstrkt-6be57-default-rtdb.firebaseio.com",
    projectId: "dstrkt-6be57",
    storageBucket: "dstrkt-6be57.appspot.com",
    messagingSenderId: "415077782045",
    appId: "1:415077782045:web:cf93ed4eeebb16de58ecf4",
    measurementId: "${config.measurementId}"
    },
    image: '/images/DSTRKT.png'
  },
      {
      id: 'oaklandtech',
      siteTitle: 'Oakland Tech Basketball',
      siteHeader: 'Oakland Tech Scorecast',
      firebaseConfig: {
        apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
    authDomain: "scdemo-c11e0.firebaseapp.com",
    databaseURL: "https://oaklandtechhs.firebaseio.com/",
    projectId: "scdemo-c11e0",
    storageBucket: "scdemo-c11e0.appspot.com",
    messagingSenderId: "505094399771",
    appId: "1:505094399771:web:9df4ef6eee14c8b0da55be",
    measurementId: "G-Y02BCY5L5L"
      },
      image: '/images/oaklandtech.png'
    },
    {
      id: 'skyline',
      siteTitle: 'Skyline Basketball',
      siteHeader: 'Skyline Scorecast',
      firebaseConfig: {
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
      authDomain: "scdemo-c11e0.firebaseapp.com",
      databaseURL: "https://skylinehs.firebaseio.com/",
      projectId: "scdemo-c11e0",
      storageBucket: "scdemo-c11e0.appspot.com",
      messagingSenderId: "505094399771",
      appId: "1:505094399771:web:0ea5dd24d4cd00cada55be",
      measurementId: "G-DP56YPZFRD"
      },
      image: '/images/skyline.png'
    },
       {
      id: 'fremont',
      siteTitle: 'Fremont Basketball',
      siteHeader: 'Fremont Scorecast',
      firebaseConfig: {
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
      authDomain: "scdemo-c11e0.firebaseapp.com",
      databaseURL: "https://fremonths.firebaseio.com/",
      projectId: "scdemo-c11e0",
      storageBucket: "scdemo-c11e0.appspot.com",
      messagingSenderId: "505094399771",
      appId: "1:505094399771:web:0ea5dd24d4cd00cada55be",
      measurementId: "G-DP56YPZFRD"
      },
      image: '/images/fremont.png'
    },
   {
  id: 'hometownfavorites',
  siteTitle: 'Hometown Favorites Basketball',
  firebaseConfig: {
    apiKey: "AIzaSyDTyZawdgFI3hL18dT2Vbc6Ug9b-acNxhA",
    authDomain: "hometownfavs-1ed94.firebaseapp.com",
    databaseURL: "https://hometownfavs-1ed94-default-rtdb.firebaseio.com",
    projectId: "hometownfavs-1ed94",
    storageBucket: "hometownfavs-1ed94.appspot.com",
    messagingSenderId: "654205579608",
    appId: "1:654205579608:web:c9488394efbe0db9dd352d",
    measurementId: "G-KMCME4ELDW"
  },
  image: '/images/Hometown.png'
  },

     {
  id: 'tri2022',
  siteTitle: 'Terry Rozier Invitational',
  firebaseConfig: {
    apiKey: "AIzaSyCyOwDqKv9CZE6JKIN8itsNqzoVOuwKWEs",
    authDomain: "donda-8b9b9.firebaseapp.com",
    databaseURL: "https://donda-8b9b9-default-rtdb.firebaseio.com",
    projectId: "donda-8b9b9",
    storageBucket: "donda-8b9b9.appspot.com",
    messagingSenderId: "394764642002",
    appId: "1:394764642002:web:c9b27cae26d00615732f0b",
    measurementId: "G-TD8MWVTS1B"
  },
  image: '/images/tri2022.jpg'
  },

   {
  id: 'rd',
  siteTitle: 'Performance Tracker',
  firebaseConfig: {
    apiKey: "AIzaSyCyOwDqKv9CZE6JKIN8itsNqzoVOuwKWEs",
    authDomain: "donda-8b9b9.firebaseapp.com",
    databaseURL: "https://donda-8b9b9-default-rtdb.firebaseio.com",
    projectId: "donda-8b9b9",
    storageBucket: "donda-8b9b9.appspot.com",
    messagingSenderId: "394764642002",
    appId: "1:394764642002:web:c9b27cae26d00615732f0b",
    measurementId: "G-TD8MWVTS1B"
  },
  image: '/images/Donda.png'
  },
     {
  id: 'riordan',
  siteTitle: 'Riordan Basketball',
  firebaseConfig: {
    apiKey: "AIzaSyCyOwDqKv9CZE6JKIN8itsNqzoVOuwKWEs",
    authDomain: "donda-8b9b9.firebaseapp.com",
    databaseURL: "https://donda-8b9b9-default-rtdb.firebaseio.com",
    projectId: "donda-8b9b9",
    storageBucket: "donda-8b9b9.appspot.com",
    messagingSenderId: "394764642002",
    appId: "1:394764642002:web:c9b27cae26d00615732f0b",
    measurementId: "G-TD8MWVTS1B"
  },
  image: '/images/riordan.png'
  },
   {
  id: 'teague',
  siteTitle: 'Team Teague Basketball',
  firebaseConfig: {
    apiKey: "AIzaSyB5IhWo1IuJtejsh5B_H7MvbrU7jNfYpYY",
    authDomain: "teague-33d63.firebaseapp.com",
    databaseURL: "https://teague-33d63-default-rtdb.firebaseio.com",
    projectId: "teague-33d63",
    storageBucket: "teague-33d63.appspot.com",
    messagingSenderId: "205713599272",
    appId: "1:205713599272:web:adf71290206fd62d09be1d",
    measurementId: "G-T7XB25JTWM"
  },
  image: '/images/Teague.png'
  },

  {
    id: 'badger',
    siteTitle: 'Badger Basketball',
    firebaseConfig: {
    apiKey: "AIzaSyB9bUkG1K_OYbZRYz4Ncu3c6VHhX55e-wY",
    authDomain: "badger-895db.firebaseapp.com",
    databaseURL: "https://badger-895db-default-rtdb.firebaseio.com",
    projectId: "badger-895db",
    storageBucket: "badger-895db.appspot.com",
    messagingSenderId: "81325427619",
    appId: "1:81325427619:web:06061efea647ee0b626816",
    measurementId: "G-2DQRV1QNKQ"
    },
    image: '/images/Badger.png'
  },
  {
    id: 'circuithoops',
    siteTitle: 'The Circuit League',
    firebaseConfig: {
    apiKey: "AIzaSyAHzku6solCv64QiwRxoaewsGSjlZEnitI",
    authDomain: "seasonticket-5a9e5.firebaseapp.com",
    databaseURL: "https://seasonticket-5a9e5-default-rtdb.firebaseio.com",
    projectId: "seasonticket-5a9e5",
    storageBucket: "seasonticket-5a9e5.appspot.com",
    messagingSenderId: "11342779790",
    appId: "1:11342779790:web:42f3251d9439e24bf440df"
    },
    image: '/images/circuit.png'
  },
  {
    id: 'soldiers',
    siteTitle: 'Soldiers Basketball',
    firebaseConfig: { 
    apiKey: "AIzaSyDMcAZtgWioILJYSK3UEn_FPbTdbZX1zWs",
    authDomain: "soldiersstats.firebaseapp.com",
    databaseURL: "https://soldiersstats-default-rtdb.firebaseio.com",
    projectId: "soldiersstats",
    storageBucket: "soldiersstats.appspot.com",
    messagingSenderId: "897298386686",
    appId: "1:897298386686:web:f248965470ea71eb0adfaf",
    measurementId: "G-E7MHP56TMV"
    },
    image: '/images/soldiers.png'
  },
  {
    id: 'sierracanyon',
    siteTitle: 'Sierra Canyon Basketball',
    firebaseConfig: { 
    apiKey: "AIzaSyA0ts1a7nTz6VFkeuEmgEp0aMKKFCAnyhA",
    authDomain: "sierracanyon-a7d77.firebaseapp.com",
    databaseURL: "https://sierracanyon-a7d77-default-rtdb.firebaseio.com",
    projectId: "sierracanyon-a7d77",
    storageBucket: "sierracanyon-a7d77.appspot.com",
    messagingSenderId: "206316659435",
    appId: "1:206316659435:web:a18c9d3d8ee5b35b4314ae",
    measurementId: "G-YHDEEEBWWQ"
    },
    image: '/images/sierracanyon.png'
  },
  {
    id: 'norcalelite',
    siteTitle: 'NorCal Elite',
    firebaseConfig: { 
    apiKey: "AIzaSyBNpSge9zR2Rh2E0t08iajc1m62dv6uCI4",
    authDomain: "norcalelite-a67c8.firebaseapp.com",
    databaseURL: "https://norcalelite-a67c8-default-rtdb.firebaseio.com",
    projectId: "norcalelite-a67c8",
    storageBucket: "norcalelite-a67c8.appspot.com",
    messagingSenderId: "727184964946",
    appId: "1:727184964946:web:c0024f38cfe3a0d6198e8b",
    measurementId: "G-0XHG5ECNSV"
    },
    image: '/images/norcal.png'
  },
  {
    id: 'hoosier',
    siteTitle: 'Hoosier Stats',
    firebaseConfig: { 
    apiKey: "AIzaSyAD_JYg_ygpIaZfciC19NxfPRboKwv9PCY",
    authDomain: "hoosierstats.firebaseapp.com",
    databaseURL: "https://hoosierstats-default-rtdb.firebaseio.com",
    projectId: "hoosierstats",
    storageBucket: "hoosierstats.appspot.com",
    messagingSenderId: "588882983549",
    appId: "1:588882983549:web:22e67d50e5835dc1100f14",
    measurementId: "G-QPFQY7SPSG"
    },
    image: '/images/hoosier.png'
  },
  {
    id: 'lca',
    firebaseConfig: { 
    apiKey: "AIzaSyAD_JYg_ygpIaZfciC19NxfPRboKwv9PCY",
    authDomain: "hoosierstats.firebaseapp.com",
    databaseURL: "https://hoosierstats-default-rtdb.firebaseio.com",
    projectId: "hoosierstats",
    storageBucket: "hoosierstats.appspot.com",
    messagingSenderId: "588882983549",
    appId: "1:588882983549:web:22e67d50e5835dc1100f14",
    measurementId: "G-QPFQY7SPSG"
    },
    image: '/images/LCA.png'
  },
  {
    id: 'chaneybb',
    siteTitle: 'Chaney Basketball',
    siteHeader: 'Chaney Scorecast',
    firebaseConfig: {
  apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
  authDomain: "scdemo-c11e0.firebaseapp.com",
  databaseURL: "https://chaney.firebaseio.com/",
  projectId: "scdemo-c11e0",
  storageBucket: "scdemo-c11e0.appspot.com",
  messagingSenderId: "505094399771",
  appId: "1:505094399771:web:39defece4dbabc7bda55be",
  measurementId: "G-RH05K8Y3ZX"
    },
    image: '/images/cheney.png'
  },
  {
    id: 'chaneyBB',
    siteTitle: 'Chaney Basketball',
    siteHeader: 'Chaney Scorecast',
    firebaseConfig: {
    apiKey: "AIzaSyBLmUmkdbleLVZB-gP-9dyNFoQZoUYqGLw",
    authDomain: "cheneystats.firebaseapp.com",
    databaseURL: "https://cheneystats-default-rtdb.firebaseio.com",
    projectId: "cheneystats",
    storageBucket: "cheneystats.appspot.com",
    messagingSenderId: "992518870407",
    appId: "1:992518870407:web:df2f5a606f954cb688af22"
    },
    image: '/images/cheney.png'
  },
  {
    id: 'hrs',
    siteTitle: 'Head-Royce Basketball',
    siteHeader: 'HRS Scorecast',
    firebaseConfig: {
      apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
  authDomain: "scdemo-c11e0.firebaseapp.com",
  databaseURL: "https://headroyce.firebaseio.com/",
  projectId: "scdemo-c11e0",
  storageBucket: "scdemo-c11e0.appspot.com",
  messagingSenderId: "505094399771",
  appId: "1:505094399771:web:6c2de4cb1d7cbcbfda55be",
  measurementId: "G-GJ0P37PS15"
    },
    image: '/images/hrs.png'
  },
  {
    id: 'HRS',
    siteTitle: 'Head-Royce Basketball',
    siteHeader: 'HRS Scorecast',
    firebaseConfig: {
    apiKey: "AIzaSyCP8g8X27lcfVajuVX9ww76br3vYf53cIY",
    authDomain: "headroyce-32d0b.firebaseapp.com",
    databaseURL: "https://headroyce-32d0b-default-rtdb.firebaseio.com",
    projectId: "headroyce-32d0b",
    storageBucket: "headroyce-32d0b.appspot.com",
    messagingSenderId: "752422606036",
    appId: "1:752422606036:web:9d7e562792d172edfb1741",
    measurementId: "G-2KV58FXHMR"
    },
    image: '/images/hrs.png'
  },
  {
    id: 'Hrs',
    siteTitle: 'Head-Royce Basketball',
    siteHeader: 'HRS Scorecast',
    firebaseConfig: {
    apiKey: "AIzaSyCP8g8X27lcfVajuVX9ww76br3vYf53cIY",
    authDomain: "headroyce-32d0b.firebaseapp.com",
    databaseURL: "https://headroyce-32d0b-default-rtdb.firebaseio.com",
    projectId: "headroyce-32d0b",
    storageBucket: "headroyce-32d0b.appspot.com",
    messagingSenderId: "752422606036",
    appId: "1:752422606036:web:9d7e562792d172edfb1741",
    measurementId: "G-2KV58FXHMR"
    },
    image: '/images/hrs.png'
  },
  {
    id: 'okfadeaway',
    siteTitle: 'OK Fadeaway Hoops',
    firebaseConfig: {
    apiKey: "AIzaSyBUS8y8V7oh7EI3d5m0neYWpR5fiHWOD1k",
    authDomain: "okfadeawayhoops.firebaseapp.com",
    databaseURL: "https://okfadeawayhoops-default-rtdb.firebaseio.com",
    projectId: "okfadeawayhoops",
    storageBucket: "okfadeawayhoops.appspot.com",
    messagingSenderId: "821589684204",
    appId: "1:821589684204:web:00199b0764172eecdddac9",
  measurementId: "G-QBDV0458KJ"
    },
    image: '/images/ok.png'
  },
  {
    id: 'wrightlegacy',
    siteTitle: 'Wright Legacy Scores',
    firebaseConfig: {
    apiKey: "AIzaSyBAVEfDqgiFWr81AN2--ZuAxiZfzPiNZCw",
    authDomain: "wrightlegacy-80b61.firebaseapp.com",
    databaseURL: "https://wrightlegacy-80b61-default-rtdb.firebaseio.com",
    projectId: "wrightlegacy-80b61",
    storageBucket: "wrightlegacy-80b61.appspot.com",
    messagingSenderId: "740872927972",
    appId: "1:740872927972:web:a0c8182443fea4c51ecaf1",
  measurementId: "G-XJPZ6ZR2W7"
    },
    image: '/images/wrightlegacy.png'
  },
  {
    id: 'sihoops',
    siteTitle: 'Rebels Stats',
    firebaseConfig: {
      apiKey: "AIzaSyCGwQ5k4CH8Z4OqSe6XxDNkgMQ-7yplih4",
      authDomain: "saintignatius-25fd9.firebaseapp.com",
      databaseURL: "https://saintignatius-25fd9-default-rtdb.firebaseio.com",
      projectId: "saintignatius-25fd9",
      storageBucket: "saintignatius-25fd9.appspot.com",
      messagingSenderId: "960050633625",
      appId: "1:960050633625:web:9eb50e2d671a6b0e811108",
      measurementId: "G-WBSGJ49LBK"
    },
    image: '/images/SI.png'
  },
  {
    id: 'oal',
    siteTitle: 'OAL Scoreboard',
    siteHeader: 'OAL Scoreboard',
    firebaseConfig: {
         apiKey: "AIzaSyAf7l0YILCI1DIp-N27T4cZrx4MtpfwDsk",
      authDomain: "scdemo-c11e0.firebaseapp.com",
      databaseURL: "https://oaklandathleticleague.firebaseio.com/",
      projectId: "scdemo-c11e0",
      storageBucket: "scdemo-c11e0.appspot.com",
      messagingSenderId: "505094399771",
      appId: "1:505094399771:web:de1cf16e965cbffbda55be",
      measurementId: "G-V8MYSTHEP2"
    },
    image: '/images/oal.png'
  },
  {
    id: 'expo',
    siteTitle: 'Rebels Stats',
    firebaseConfig: {
      apiKey: "AIzaSyBl4zI8D8Ho2Wlerww0skkDi5tJnQnOR0Y",
      authDomain: "ladyheat.firebaseapp.com",
      databaseURL: "https://ladyheat-default-rtdb.firebaseio.com",
      projectId: "ladyheat",
      storageBucket: "ladyheat.appspot.com",
      messagingSenderId: "859190741431",
      appId: "1:859190741431:web:60ea0f8afa71c7b47b8032",
      measurementId: "G-YCSN2D4HHF"
    },
    image: '/images/exposure.png'
  },
  {
    id: 'ymca-youngstown',
    siteTitle: 'Rebels Stats',
    firebaseConfig: {
      apiKey: "AIzaSyBl4zI8D8Ho2Wlerww0skkDi5tJnQnOR0Y",
      authDomain: "ladyheat.firebaseapp.com",
      databaseURL: "https://ladyheat-default-rtdb.firebaseio.com",
      projectId: "ladyheat",
      storageBucket: "ladyheat.appspot.com",
      messagingSenderId: "859190741431",
      appId: "1:859190741431:web:60ea0f8afa71c7b47b8032",
      measurementId: "G-YCSN2D4HHF"
    },
    image: '/images/ymca.png'
  },
  {
    id: 'marquee',
    siteTitle: 'Rebels Stats',
    firebaseConfig: {
      apiKey: "AIzaSyCdjiB94Tta6Re5Oc7FzvmBM2PxfIUD9bo",
      authDomain: "marqueehoops-d1b6b.firebaseapp.com",
      projectId: "marqueehoops-d1b6b",
      storageBucket: "marqueehoops-d1b6b.appspot.com",
      messagingSenderId: "524005500658",
      appId: "1:524005500658:web:e88f6ca957342cde21126b",
  measurementId: "G-MVVTNGB6JM"
    },
    image: '/images/marquee.png'
  },
  {
    id: 'zerogravity',
    siteTitle: 'Rebels Stats',
    firebaseConfig: {
      apiKey: "AIzaSyAx12AMPStt0lAJKDBFmsIlniAiw3DFzbs",
      authDomain: "zero-gravity-55d4a.firebaseapp.com",
      projectId: "zero-gravity-55d4a",
      storageBucket: "zero-gravity-55d4a.appspot.com",
      messagingSenderId: "945543249917",
      appId: "1:945543249917:web:970a5d456944878dd46394",
      measurementId: "G-XGVM83R8KS"
    },
    image: '/images/dc.png'
  },
  {
    id: 'exposure',
    siteTitle: 'Rebels Stats',
    firebaseConfig: {
      apiKey: "AIzaSyBl4zI8D8Ho2Wlerww0skkDi5tJnQnOR0Y",
      authDomain: "ladyheat.firebaseapp.com",
      databaseURL: "https://ladyheat-default-rtdb.firebaseio.com",
      projectId: "ladyheat",
      storageBucket: "ladyheat.appspot.com",
      messagingSenderId: "859190741431",
      appId: "1:859190741431:web:60ea0f8afa71c7b47b8032",
      measurementId: "G-YCSN2D4HHF"
    },
    image: '/images/exposure.png'
  },
];

export default databases