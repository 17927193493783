import React, { useState, useEffect } from 'react';
import StatItem from './StatItem';
import { BsChevronCompactDown } from 'react-icons/bs'
import { BsChevronCompactUp } from 'react-icons/bs'


function ScoreboardModeTab({ currentGame, leftTeam, rightTeam, eventData, homePlayers, awayPlayers, avatar, app }) {
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [events, setEvents] = useState([])
  const [isDropdown, setIsDropDown] = useState(false)
  const [statsData, setStatsData] = useState({})
  const [graphData, setGraphData] = useState([])

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((s) => s - 1);
      }, 1000);
    } else if (!isActive && seconds === 0) {
      resetTimer();
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const resetTimer = () => {
    setSeconds(60);
  };

  const toggleTimer = () => {
    setIsActive(!isActive);
    if (seconds === 0) {
      resetTimer();
    }
  };

  const getRandomLightColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 50; // Fixed saturation for consistency
    const lightness = 90; // Fixed lightness for consistency
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const getFirstLetter = (teamName) => {
    return teamName.charAt(0).toUpperCase();
  };

  const getTimerColor = () => {
    if (seconds > 30) return 'green';
    if (seconds > 10) return 'yellow';
    return 'red';
  };

  const timerDisplay = isActive
    ? `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`
    : 'Start Timer';

    // Styles for the component
  const scoreboardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#333',
    color: 'white',
    padding: '40px',
    borderRadius: '10px',
    margin: '20px auto',
    width: '100%',
    maxWidth: '1400px',
  };

  const teamContainerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px', // Space between timer and scores
  };

  const teamStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 30px',
  };

const teamImageStyle = {
  width: '200px',
  height: '200px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: '50%',
  marginBottom: '10px',
  backgroundImage: `url(${avatar})`, // Use avatar prop as background image
};

const placeholderStyle = {
  width: '200px', // Same as image size
  height: '200px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: '50%',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: getRandomLightColor(),
  color: '#000', // Black color for the text
  fontSize: '120px', // Big and bold for visibility
  fontWeight: 'bold',
};

  const scoreStyle = {
    fontWeight: 'bold',
    fontSize: '5em',
  };

  const nameStyle = {
    fontSize: '1.5em',
    margin: '5px 0',
  };

  const timerTextStyle = {
    color: getTimerColor(),
    fontWeight: 'bold',
    fontSize: '3em', // Larger font size for the timer
    cursor: 'pointer',
    margin: '20px 0',
  };

  const mapEvent = {
  '1g': '1pt Make',
  '2g': '2pt Make',
  '2t': '2pt Make',
  '3t': '3pt Make',
  '2c': '2pt Make',
  '3c': '3pt Make',
  '3g': '3pt Make',
  '1m': '1pt Miss',
  '2m': '2pt Miss',
  '3m': '3pt Miss',
  'r': 'Rebound',
  'or': 'Rebound',
  't': 'Turnover',
  's': 'Steal',
  'a': 'Assist',
  '2gr': 'Assist',
  '3gr': 'Assist',
  'b': 'Block',
  'fp': 'Personal Foul'
}

  const mapPoint = {
  '1g': 1,
  '2g': 2,
  '3g': 3,
  '2t': 2,
  '3t': 3,
  '2c': 2,
  '3c': 3,
}

useEffect(() => {
    if (awayPlayers && homePlayers && eventData) {
      let events = []
      let stats_initial = {
        shots_made: 0,
        total_shots: 0,
        made_3pt: 0,
        total_3pt_shots: 0,
        made_2pt: 0,
        total_2pt_shots: 0,
        made_ft: 0,
        total_ft_shots: 0,
        three_pointers: 0,
        two_pointers: 0,
        free_throws: 0,
        assists: 0,
        rebounds: 0,
        def_rebounds: 0,
        off_rebounds: 0,
        steals: 0,
        blocks: 0,
        turnovers: 0,
        fouls: 0,
        biggest_lead: 0
      }

      let stats_data = {
        home: { ...stats_initial },
        away: { ...stats_initial }
      }

      const mapTeam = (id) => {
        if (id === currentGame?.away) {
          return currentGame?.a_name
        }
        if (id === currentGame?.home) {
          return currentGame?.h_name
        }
      }

      const checkAwayOrHome = id => {
        if (id === currentGame?.away) {
          return 'away'
        }
        if (id === currentGame?.home) {
          return 'home'
        }
      }

      const mapPlayer = (id) => {
        let homePlayer = homePlayers.find(i => i.id === id)
        if (homePlayer) {
          return homePlayer
        }

        let awayPlayer = awayPlayers.find(i => i.id === id)
        if (awayPlayer) {
          return awayPlayer
        }
      }

      const mapStatData = (stats_data, event, awayOrHome, newEvent) => {

        switch (event) {
          case '2g':
            stats_data[awayOrHome].shots_made += 1
            stats_data[awayOrHome].total_shots += 1
            stats_data[awayOrHome].made_2pt += 1
            stats_data[awayOrHome].total_2pt_shots += 1
            break;
          case '3g':
            stats_data[awayOrHome].shots_made += 1
            stats_data[awayOrHome].total_shots += 1
            stats_data[awayOrHome].made_3pt += 1
            stats_data[awayOrHome].total_3pt_shots += 1
            break;
            case '2t':
            stats_data[awayOrHome].shots_made += 1
            stats_data[awayOrHome].total_shots += 1
            stats_data[awayOrHome].made_2pt += 1
            stats_data[awayOrHome].total_2pt_shots += 1
            break;
          case '3t':
            stats_data[awayOrHome].shots_made += 1
            stats_data[awayOrHome].total_shots += 1
            stats_data[awayOrHome].made_3pt += 1
            stats_data[awayOrHome].total_3pt_shots += 1
            break;
              case '2c':
            stats_data[awayOrHome].shots_made += 1
            stats_data[awayOrHome].total_shots += 1
            stats_data[awayOrHome].made_2pt += 1
            stats_data[awayOrHome].total_2pt_shots += 1
            break;
          case '3c':
            stats_data[awayOrHome].shots_made += 1
            stats_data[awayOrHome].total_shots += 1
            stats_data[awayOrHome].made_3pt += 1
            stats_data[awayOrHome].total_3pt_shots += 1
            break;
          case '2m':
            stats_data[awayOrHome].total_shots += 1
            stats_data[awayOrHome].total_2pt_shots += 1
            break;
          case '3m':
            stats_data[awayOrHome].total_shots += 1
            stats_data[awayOrHome].total_3pt_shots += 1
            break;
          case '1g':
            stats_data[awayOrHome].made_ft += 1
            stats_data[awayOrHome].total_ft_shots += 1
            break;
          case '1m':
            stats_data[awayOrHome].total_ft_shots += 1
            break;
          case 'r':
            stats_data[awayOrHome].rebounds += 1
            stats_data[awayOrHome].def_rebounds += 1
            break;
          case 'or':
            stats_data[awayOrHome].rebounds += 1
            stats_data[awayOrHome].off_rebounds += 1
            break;
          case 't':
            stats_data[awayOrHome].turnovers += 1
            break;
          case 's':
            stats_data[awayOrHome].steals += 1
            break;
          case 'a':
            stats_data[awayOrHome].assists += 1
            break;
            case '2gr':
            stats_data[awayOrHome].assists += 1
            break;
            case '3gr':
            stats_data[awayOrHome].assists += 1
            break;
          case 'b':
            stats_data[awayOrHome].blocks += 1
            break;
          case 'fp':
            stats_data[awayOrHome].fouls += 1
            break;
          case 'ft':
            stats_data[awayOrHome].fouls += 1
            break;
          case 'ff':
            stats_data[awayOrHome].fouls += 1
            break;
          default:
            break;
        }
        if (newEvent?.homePoint > newEvent?.awayPoint) {
          let new_biggest_lead = newEvent?.homePoint - newEvent?.awayPoint
          if (stats_data.home.biggest_lead < new_biggest_lead) {
            stats_data.home.biggest_lead = new_biggest_lead
          }
        }
        if (newEvent?.homePoint < newEvent?.awayPoint) {
          let new_biggest_lead = newEvent?.awayPoint - newEvent?.homePoint
          if (stats_data.away.biggest_lead < new_biggest_lead) {
            stats_data.away.biggest_lead = new_biggest_lead
          }
        }
      }

      let awayPoint = 0
      let homePoint = 0
      for (let i = 0; i < eventData.length; i++) {
        let event = eventData[i].split(' ')
        if (event.length === 3) {
          let newEvent = {
            awayOrHome: checkAwayOrHome(event[0]),
            team: mapTeam(event[0]),
            player: mapPlayer(event[1]),
            event: mapEvent?.[event[2]],
            homePoint: checkAwayOrHome(event[0]) === 'home' ? homePoint += mapPoint?.[event[2]] || 0 : homePoint,
            awayPoint: checkAwayOrHome(event[0]) === 'away' ? awayPoint += mapPoint?.[event[2]] || 0 : awayPoint,
          }
          events.push(newEvent)
          mapStatData(stats_data, event[2], checkAwayOrHome(event[0]), newEvent)
        }
        if (event.length === 2) {
          let newEvent = {
            awayOrHome: checkAwayOrHome(event[0]),
            team: mapTeam(event[0]),
            player: '',
            event: mapEvent?.[event[1]],
            homePoint: checkAwayOrHome(event[0]) === 'home' ? homePoint += mapPoint?.[event[1]] || 0 : homePoint,
            awayPoint: checkAwayOrHome(event[0]) === 'away' ? awayPoint += mapPoint?.[event[1]] || 0 : awayPoint,
          }
          events.push(newEvent)
          mapStatData(stats_data, event[1], checkAwayOrHome(event[0]), newEvent)
        }
      }
      setStatsData(stats_data)

      setEvents(events.reverse())

      events = JSON.parse(JSON.stringify(events))
      events.reverse()

      let xAxisMap = events.map((_, index) => index)
      let homePointGraph = events.map(i => i.homePoint)
      let awayPointGraph = events.map(i => i.awayPoint)
      let graphData = {
        labels: xAxisMap,
        datasets: [
          {
            label: currentGame?.h_name,
            fill: false,
            lineTension: 0,
            borderColor: '#7494b1',
            data: homePointGraph
          },
          {
            label: currentGame?.a_name,
            fill: false,
            lineTension: 0,
            borderColor: '#000',
            data: awayPointGraph
          },
        ],
      }
      setGraphData(graphData)
    }
  }, [eventData, homePlayers, awayPlayers])

  if (!currentGame || !eventData) {
    return 'Loading stats...'
  }

  const options = {
    scales: {
      yAxes: [{
        display: true,
        position: 'right',
      }],
      xAxes: [{
        display: false,
      }]
    }
  }
  let players = Number(currentGame?.a_score) > Number(currentGame?.h_score) ? awayPlayers : homePlayers
  if (!players || players?.length < 1) {
    players = awayPlayers.concat(homePlayers)
  }

  const stats = [
    {
      id: 'field_goals',
      title: 'FIELD GOALS',
      showPercent: true,
      showPreview: true,
      home: {
        percent: (statsData?.home?.shots_made / statsData?.home?.total_shots) * 100 || 0,
        score: statsData?.home?.shots_made,
        total: statsData?.home?.total_shots,
      },
      away: {
        percent: (statsData?.away?.shots_made / statsData?.away?.total_shots) * 100 || 0,
        score: statsData?.away?.shots_made,
        total: statsData?.away?.total_shots,
      },
    },
    {
      id: 'two_pointers',
      title: '2PT SHOTS',
      showPercent: true,
      showPreview: true,
      home: {
        percent: (statsData?.home?.made_2pt / statsData?.home?.total_2pt_shots) * 100 || 0,
        score: statsData?.home?.made_2pt,
        total: statsData?.home?.total_2pt_shots,
      },
      away: {
        percent: (statsData?.away?.made_2pt / statsData?.away?.total_2pt_shots) * 100 || 0,
        score: statsData?.away?.made_2pt,
        total: statsData?.away?.total_2pt_shots,
      },
    },
    {
      id: 'three_pointers',
      title: '3PT SHOTS',
      showPercent: true,
      showPreview: true,
      home: {
        percent: (statsData?.home?.made_3pt / statsData?.home?.total_3pt_shots) * 100 || 0,
        score: statsData?.home?.made_3pt,
        total: statsData?.home?.total_3pt_shots,
      },
      away: {
        percent: (statsData?.away?.made_3pt / statsData?.away?.total_3pt_shots) * 100 || 0,
        score: statsData?.away?.made_3pt,
        total: statsData?.away?.total_3pt_shots,
      },
    },
    {
      id: 'free_throws',
      title: 'FREE THROWS',
      showPercent: true,
      showPreview: false,
      home: {
        percent: (statsData?.home?.made_ft / statsData?.home?.total_ft_shots) * 100 || 0,
        score: statsData?.home?.made_ft,
        total: statsData?.home?.total_ft_shots,
      },
      away: {
        percent: (statsData?.away?.made_ft / statsData?.away?.total_ft_shots) * 100 || 0,
        score: statsData?.away?.made_ft,
        total: statsData?.away?.total_ft_shots,
      },
    },
    {
      id: 'rebounds',
      title: 'REBOUNDS',
      showPreview: false,
      home: {
        score: statsData?.home?.rebounds,
      },
      away: {
        score: statsData?.away?.rebounds,
      },
    },
    {
      id: 'turnovers',
      title: 'TURNOVERS',
      showPreview: false,
      home: {
        score: statsData?.home?.turnovers + statsData?.away?.steals,
      },
      away: {
        score: statsData?.away?.turnovers + statsData?.home?.steals,
      },
    },
    {
      id: 'def_rebounds',
      title: 'DEF REBOUNDS',
      showPreview: false,
      home: {
        score: statsData?.home?.def_rebounds,
      },
      away: {
        score: statsData?.away?.def_rebounds,
      },
    },
    {
      id: 'off_rebounds',
      title: 'OFF REBOUNDS',
      showPreview: false,
      home: {
        score: statsData?.home?.off_rebounds,
      },
      away: {
        score: statsData?.away?.off_rebounds,
      },
    },
    {
      id: 'fouls',
      title: 'FOULS',
      showPreview: false,
      home: {
        score: statsData?.home?.fouls,
      },
      away: {
        score: statsData?.away?.fouls,
      },
    },
    {
      id: 'assists',
      title: 'ASSISTS',
      home: {
        score: statsData?.home?.assists,
      },
      away: {
        score: statsData?.away?.assists,
      },
    },
    {
      id: 'blocks',
      title: 'BLOCKS',
      showPreview: false,
      home: {
        score: statsData?.home?.blocks,
      },
      away: {
        score: statsData?.away?.blocks,
      },
    },
    {
      id: 'biggest_lead',
      title: 'BIGGEST LEAD',
      showPreview: false,
      home: {
        score: statsData?.home?.biggest_lead,
      },
      away: {
        score: statsData?.away?.biggest_lead,
      },
    },
  ]

return (
  <div>
    <div style={scoreboardStyle}>
      <div onClick={toggleTimer} style={timerTextStyle}>
        {timerDisplay}
      </div>
      <div style={teamContainerStyle}>
        <div style={teamStyle}>
          <div style={leftTeam ? teamImageStyle : placeholderStyle}>
            {!leftTeam && getFirstLetter(currentGame?.a_name)}
          </div>
          <div style={nameStyle}>{currentGame?.a_name}</div>
          <div style={scoreStyle}>{currentGame?.a_score}</div>
        </div>
        
        <div style={teamStyle}>
          <div style={rightTeam ? teamImageStyle : placeholderStyle}>
            {!rightTeam && getFirstLetter(currentGame?.h_name)}
          </div>
          <div style={nameStyle}>{currentGame?.h_name}</div>
          <div style={scoreStyle}>{currentGame?.h_score}</div>
        </div>
      </div>
    </div>
    
    <div className='stats-container'>
      {stats.filter(i => i?.showPreview).map((item, index) => (
        <StatItem item={item} key={index} />
      ))}
      {isDropdown ? (
        stats.filter(i => !i?.showPreview).map((item, index) => (
          <StatItem item={item} key={index} />
        ))
      ) : null}
      <div className='dropdown-wrapper'>
        {isDropdown ? (
          <div className='dropdown-button' onClick={() => setIsDropDown(false)}>
            <BsChevronCompactUp size={25} />
          </div>
        ) : (
          <div className='dropdown-button' onClick={() => setIsDropDown(true)}>
            <BsChevronCompactDown size={25} />
          </div>
        )}
      </div>
    </div>
  </div>
);

}

export default ScoreboardModeTab;